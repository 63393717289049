/* eslint-disable no-param-reassign */
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment';


import 'primeicons/primeicons.css';

import css from './Calendar.module.scss';
import Logo from '../../components/Logo';
import { useStoreState } from '../../store/hooks';

import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { usePotentialCupGames } from '../../hooks';
import { findOpponent } from '../../hooks/PathToGlory';
import React from 'react';
import { defaultSeason } from '../../util';
import GameCard from '../../components/Gamecard';
import { DaysRemaining } from '../../components/DaysRemaining';
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const CalendarDay = ({day}: {day: any}) => {
let intensity = 600;
if (day.inCurrentMonth || day.inSeason) {
  intensity = 400;
}

let className = css.day;
if (day?.leagueWinner?.color && !day.holidayName) {
  className += ` bg-${day?.leagueWinner?.color}-${intensity}`;
} else if (day.inCurrentMonth) {
  className += ' bg-white';
} else {
  className += ` bg-zinc-500`;
}

let opponentClassName = css.opponent;
if (day?.leagueOpponent?.color) {
  opponentClassName += ` bg-${day?.leagueOpponent?.color}-${intensity}`;
}
console.log(day.holidayName, day)
return (
  <div className={className}>
    <div className={`flex justify-between text-base m-1`}>
      <span className={css.date}>{day.day}</span>
    </div>
      {!day.holidayName && <div className='grow'>
          <Logo teamId={day.currentChamp} size={undefined} onClick={undefined} />
        </div>
      }
      {day.loser && <div className={`${opponentClassName} p-1`}>
          <Logo size="extra-small" teamId={day.loser} onClick={undefined} />
        </div>
      }
    {day.holidayName && <div className='grow flex flex-col justify-center items-center text-center'>
      <i className={`pi pi-${day.holidayIcon}`} style={{ fontSize: '2.5rem' }}></i>
      <p className='text-base' style={{color: 'black'}}>{day.holidayName}</p>
    </div>}
  </div>
);
}
const Calendar = ({leagueId, leagueLookup}: {leagueId?: number, leagueLookup?: any}) => {
  const potentialSeasons = useStoreState(state => state.potentialSeasons);
  const currentSeasonId = useStoreState(state => state.currentSeasonId);
  const seasonResults = useStoreState(state => state.seasonResults);
  const seasons = useStoreState(state => state.seasons);

  const TODAY = Date.now();
  // eslint-disable-next-line max-len
  const [currentMonth, setCurrentMonth] = useState<any>(potentialSeasons.find(potentialSeason => potentialSeason.code === currentSeasonId)?.startDate);// moment(seasonDates[currentSeason].startDate).toISOString());
  const [displayedMonth, setDisplayedMonth] = useState(moment(currentMonth).format('MMMM'));
  const [visibleCalendarDays, setVisibleCalendarDays] = useState<any>([]);
  const [currentChamp, setCurrentChamp] = useState<any>();
  const {
    potentialCupGames,
  } = usePotentialCupGames(false, 15);

  useEffect(() => {
    //TODO - DELETE - hack for BEDlaM
    if (seasonResults && seasonResults[2025] && leagueId === 2) {
      seasonResults[2025].splice(0, 4);
    }
    const events = seasonResults[currentSeasonId];
    let lastChamp;
    
    if (events?.length) {
      lastChamp = events[events.length - 1].currentChamp;
    } else {
      // if (potentialSeasons.find(potentialSeason => potentialSeason.code === currentSeasonId).currentChamp
      lastChamp = potentialSeasons.find(potential => potential.code === currentSeasonId)?.previousStanleyCupChampName
    }

    setCurrentChamp(lastChamp);
  }, [seasonResults, potentialSeasons, currentSeasonId]);

  useEffect(() => {
    const season = potentialSeasons.find(potentialSeason => potentialSeason.code === currentSeasonId);
    let startDate, endDate, month;
    if (season) {
      startDate = season.startDate;
      endDate = season.endDate;
    }
    
    if (currentSeasonId === defaultSeason.code && moment().diff(startDate) > 0 && moment().diff(endDate, 'month') <= 0) {
      month = moment().format('YYYY-MM-DD');
    } else {
      month = moment(startDate).format('YYYY-MM-DD');
    }
    setCurrentMonth(month);
  }, [potentialSeasons, currentSeasonId]);
  
  useEffect(() => {
    setDisplayedMonth(moment(currentMonth).format('MMMM YY'));
  }, [currentMonth]); 
 
  useEffect(() => {
    setVisibleCalendarDays([]);
    const firstDay = moment(currentMonth).date(1).day();
    const visibleDates:any = [];
    const season = potentialSeasons.find(potentialSeason => potentialSeason.code === currentSeasonId);
    const endDate = season?.endDate;

    for (let i = firstDay; i > 0; i--) {
      visibleDates.push({
        day: moment(currentMonth).date(1).subtract(i, 'days').date(),
        isoString: moment(currentMonth).date(1).subtract(i, 'days').toISOString(),
        inCurrentMonth: false,
      });
    }

    for (let i = 1; i <= moment(currentMonth).daysInMonth(); i++) {
      visibleDates.push({
        day: i,
        isoString: moment(currentMonth).date(i).toISOString(),
        inCurrentMonth: true,
      });
    }

    const futureDays = 7 - (visibleDates.length % 7);
    for (let i = 1; i <= futureDays; i++) {
      visibleDates.push({
        day: i,
        isoString: moment(currentMonth).date(moment(currentMonth).daysInMonth()).add(i, 'days').toISOString(),
        inCurrentMonth: false,
      });
    }

    const results = seasonResults[currentSeasonId];
    if (results?.length) {
      visibleDates.forEach((date:any, i:number) => {
        const day = moment(date.isoString).format('YYYY-MM-DD');
        const nextGame = potentialCupGames[0];
        const lastGameWon = results[results.length - 1];
        const game = [...results, {
          date: nextGame?.date,
          loser: nextGame ? findOpponent(nextGame, lastGameWon.currentChamp) : '',
          changeEvent: true,
          currentChamp: '',
        }]?.find(event => event.date === day);
        
        const leagueWinner = leagueLookup ? leagueLookup[(game?.currentChamp || '')] : undefined;
        const leagueOpponent = leagueLookup ? leagueLookup[game?.loser] : undefined;

        visibleDates[i] = {
          ...date,
          ...game,
          leagueWinner,
          leagueOpponent
        }

        if (moment(date.isoString).diff(endDate, 'day') === 0) {
          visibleDates[i] = {
            ...date,
            holidayName: 'Season over',
            holidayIcon: 'x',
            inSeason: false,
          };    
        }

      });
    } else {
      visibleDates.forEach((date:any, i:number) => {
        const day = moment(date.isoString).format('YYYY-MM-DD');
        const season = seasons[currentSeasonId];
        const element = season?.find(({away, home}: any) => {
          return (currentChamp === away.id || currentChamp === home.id);
        });
        if (element?.date_game === day) {
          visibleDates[i] = {
            ...date,
            loser: element?.id,
          };
        } else {
          visibleDates[i] = {
            ...date,
          };
        }
      });
    }


    setVisibleCalendarDays(visibleDates);
  }, [seasonResults, currentChamp, currentMonth, currentSeasonId, seasons, leagueLookup, potentialCupGames]);

  const onChangeMonth = useCallback((e:any) => {
    if (e.target.value === 'previous') {
      setCurrentMonth(moment(currentMonth).subtract(1, 'month').toISOString());
    } else {
      setCurrentMonth(moment(currentMonth).add(1, 'month').toISOString());
    }
  }, [currentMonth, setCurrentMonth]);
  
  const firstMonth = useMemo(() => (moment(potentialSeasons.find(season => season.code === currentSeasonId)?.startDate).startOf('month')), [currentSeasonId, potentialSeasons]);
  const finalMonth = useMemo(() => (moment(potentialSeasons.find(season => season.code === currentSeasonId)?.endDate).startOf('month')), [currentSeasonId, potentialSeasons]);

  const HighlightedTeam = ({teamId, category} : {teamId?:number, category?: string}) => (
    <Panel className="m-4" header={category}>
      <div className="flex justify-center items-center">
        <Logo teamId={teamId} size={'medium'} onClick={undefined}/>
      </div>
    </Panel>
  );

  const enableNextButton = useMemo(() => {
    return moment(currentMonth).diff(finalMonth) <= 0 && (moment(currentMonth).endOf('month').diff(TODAY) < 0 || moment(currentMonth).endOf('month').diff(potentialCupGames[0]?.date) < 0)
  }, [TODAY, currentMonth, finalMonth, potentialCupGames]);

  const disablePreviousButton = useMemo(() => {
    return moment(firstMonth).diff(currentMonth, 'month') >= 0
  }, [currentMonth, firstMonth]);

  return (
    <div className={css.container}>
      <div className="flex justify-center flex-wrap m-6">
        <HighlightedTeam teamId={potentialSeasons.find(season => season.code === currentSeasonId)?.previousStanleyCupChamp} category='Starting Champ' />
        <HighlightedTeam teamId={currentChamp} category='Current Champ' />
        <GameCard game={potentialCupGames[0]} header={'Next Game'} allActive />
        <DaysRemaining />
      </div>
      <div className={css.controls}>
        <Button
          type="button"
          severity="secondary"
          disabled={disablePreviousButton}
          value="previous"
          onClick={onChangeMonth}
        >
          previous
        </Button>
        <div className={css.monthTitle}>{displayedMonth}</div>
        <Button
          type="button"
          severity="secondary"
          disabled={enableNextButton === false}
          value="next"
          onClick={onChangeMonth}
        >
          next
        </Button>
      </div>
      <div className={css.calendar}>
        <div className={`${css.header} bg-zinc-400 text-center`}>
          {daysOfWeek.map((day) => (<div className={css.day} key={day}>{day}</div>))}
        </div>
        <div className={`${css.days} bg-zinc-400`}>
          {visibleCalendarDays.map((day: any) => (<CalendarDay key={day.isoString} day={day} />))}          
        </div>
      </div>
    </div>
  );
};

export default Calendar;
